import { getFragmentData } from '../../gql/__generated__';
import { setReplayBet, setSlotConfig, setUserLastBetResult } from '../../gql/cache';
import client from '../../gql/client';
import { historyNodeFragment } from '../../gql/fragment';
import { replaySlotHistoryGql, slotHistoryGql } from '../../gql/query';
import { queryParams } from '../../utils';

export const useLastBet = () => {
  const getLastBet = async () => {
    const query = queryParams.has('replayBetId') ? replaySlotHistoryGql : slotHistoryGql;
    const betsData = await client.query({
      query,
      variables: {
        input: { last: 1, filter: { slotId: setSlotConfig().id } },
      },
      fetchPolicy: 'network-only',
    });
    if (betsData.data.bets?.edges[0]) {
      const node = getFragmentData(historyNodeFragment, betsData.data.bets?.edges[0].node);
      if (setReplayBet() && node.data.features.gameRoundStore && node.data.features.gameRoundStore.playedFS > 0) {
        node.data.features.gameRoundStore.playedFS -= 1;
      }
      setUserLastBetResult(node);
    }
  };
  return { getLastBet };
};
