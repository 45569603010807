import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { PopupOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsPopupOpeningInProgress,
  setIsReplay,
  setSlotMachineInitialized,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { filterDisable, filterMouseDown, filterMouseOver, isFreeSpinsMode, isMobileDevice } from '../../utils/helper';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from '../background/config';
import {
  EventTypes,
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  REEL_HEIGHT,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import { GameMode } from '../config/bonusInfo';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  private windowWidth: number;

  private windowHeight: number;

  private isSceneChange: boolean;

  private isFreeRoundBonus: boolean;

  private isInTransition: boolean;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.isSceneChange = false;
    this.isFreeRoundBonus = false;
    this.isInTransition = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_FADEOUT, (isFadeOut: boolean) => {
      this.handleDisable(isFadeOut);
    });

    eventManager.on(EventTypes.SET_SCENE_CHANGE, (isSceneChange: boolean) => {
      this.isSceneChange = isSceneChange;
      this.handleDisable(isSceneChange);
    });
    eventManager.addListener(EventTypes.SET_BROKEN_BUY_FEATURE, (isBroken: boolean) => {
      this.handleDisable(isBroken);
    });
    eventManager.on(EventTypes.UI_VISIBLE, (visible) => {
      this.visible = visible !== undefined ? visible : !this.visible;
    });
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
    eventManager.on(EventTypes.SET_IS_IN_TRANSITION, (isInTransition: boolean) => {
      this.isInTransition = isInTransition;
      this.handleDisable(isInTransition);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.filters = [filterMouseOver()];
        this.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.filters = null;
        this.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.filters = [filterMouseDown()];
        this.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.filters = null;
        this.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, isMobileDevice() ? 120 : 120, isMobileDevice() ? 150 : 100);

    return text;
  }

  private onClick(): void {
    if (
      !this.isDisabled &&
      setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE &&
      !setIsAutoSpins() &&
      setSlotMachineInitialized()
    ) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.MultiUp_1 });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isSceneChange && !disable) return;
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isInTransition && !disable) return;
    updateTextScale(
      this.text,
      this.windowWidth > this.windowHeight ? 120 : 150,
      this.windowWidth > this.windowHeight ? 150 : 180,
      2,
    );

    if (this.isAutoSpinInProgress) {
      this.btn.buttonMode = false;
      this.btn.filters = [filterDisable()];
      this.text.style = buyFeatureDisableTextStyle;
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
      return;
    }

    this.isDisabled = disable;

    if (disable) {
      this.btn.buttonMode = false;
      this.btn.filters = [filterDisable()];
      this.text.style = buyFeatureDisableTextStyle;
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    } else {
      this.btn.buttonMode = true;
      this.btn.filters = null;
      this.text.style = buyFeatureTextStyle;
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    this.setPosition(width, height);

    this.text.position.set(this.btn.width / 2, this.btn.height / 2);

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    if (width > height) {
      this.x = -54 - FEATURE_BTN_LANDSCAPE_WIDTH;
      this.y = REEL_HEIGHT + 10;
    } else {
      this.x = SLOTS_CONTAINER_WIDTH - this.btn.width * 1.5;
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 4;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinsMode(settings.mode) || setIsReplay()) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
